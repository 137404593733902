import { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

type SiteMetadata = {
  siteTitle: string
  siteName: string
  siteDescription: string
  siteUrl: string
}

export const useSiteMetadata = (): SiteMetadata => {
  const { site } = useStaticQuery<GatsbyTypes.siteMetaData>(
    graphql`
      query siteMetaData {
        site {
          siteMetadata {
            siteTitle: title
            siteName: name
            siteDescription: description
            siteUrl: url
          }
        }
      }
    `
  )
  return site.siteMetadata
}

/**
 * window.matchMedia
 * @param mediaQuery string
 */
export const useMediaQuery = (mediaQuery: string): boolean | null => {
  if (typeof window !== 'object') {
    return null
  }

  const [isVerified, setIsVerified] = useState(!!window.matchMedia(mediaQuery).matches)

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery)

    const documentChangeHandler = () => setIsVerified(!!mediaQueryList.matches)

    mediaQueryList.addListener(documentChangeHandler)

    // documentChangeHandler()
    return () => {
      mediaQueryList.removeListener(documentChangeHandler)
    }
  }, [mediaQuery])

  return isVerified
}
