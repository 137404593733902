import React from 'react'
import { ThemeProvider } from 'emotion-theming'
import { theme } from 'src/styles'
import Header from 'src/components/organisms/Header'
import Footer from 'src/components/organisms/Footer'
import { Main } from 'src/components/layouts/Main'
import SiteWrapper from 'src/components/layouts/SiteWrapper'
import PageMeta from 'src/components/PageMeta'
import 'typeface-roboto-condensed'
import 'src/styles/all.scss'

type Props = {
  hasFeaturedImage?: boolean
}

const Layout: React.FC<Props> = (props) => {
  const { hasFeaturedImage = false, children } = props
  return (
    <>
      <PageMeta />
      <ThemeProvider theme={theme}>
        <SiteWrapper hasFeaturedImage={hasFeaturedImage}>
          <Header />
          <Main>{children}</Main>
          <Footer />
        </SiteWrapper>
      </ThemeProvider>
    </>
  )
}

export default Layout
