import React from 'react'
import styled, { mediaQuery } from 'src/styles'

export const Main: React.FC = ({ children }) => {
  return <ScMain>{children}</ScMain>
}

const ScMain = styled.main`
  padding-bottom: 40px;
  ${mediaQuery('sm')} {
    padding-bottom: 120px;
  }
`
