import React from 'react'
import styled from 'src/styles'
import { ComponentStyleProps } from 'src/types'

type Props = {
  isOpen: boolean
  handleEvent: () => void
} & ComponentStyleProps

export const MenuButton: React.FC<Props> = (props) => {
  const { isOpen, handleEvent } = props
  return (
    <ScMenuButton {...props} onClick={() => handleEvent()}>
      <ScMenuBar isOpen={isOpen} />
      <ScMenuBar isOpen={isOpen} />
      <ScMenuBar isOpen={isOpen} />
      <ScMenuBar isOpen={isOpen} />
    </ScMenuButton>
  )
}

const ScMenuButton = styled.button`
  background-color: transparent;
  border: none;
  height: 40px;
  outline: none;
  padding: 0;
  position: relative;
  width: 40px;
`

type MenuBarProps = {
  isOpen: boolean
}

const ScMenuBar = styled.span<MenuBarProps>`
  ${({ theme }) => `
    background-color: ${theme.color.button.primary};
  `}
  height: 2px;
  border-radius: 2px;
  width: 28px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s;
  &:first-of-type {
    top: 12px;
  }
  &:last-of-type {
    bottom: 12px;
  }
  &:nth-of-type(2),
  &:nth-of-type(3) {
    opacity: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  ${({ isOpen }) =>
    isOpen &&
    `
    &:first-of-type {
      opacity: 0;
      top: 8px;
    }
    &:last-of-type {
      opacity: 0;
      bottom: 8px;
    }
    &:nth-of-type(2) {
      opacity: 1;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &:nth-of-type(3) {
      opacity: 1;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  `}
`
