import React from 'react'
import { Helmet } from 'react-helmet'
import { useSiteMetadata } from 'src/hooks'
import { useLocation } from '@reach/router'
import { withPrefix } from 'gatsby'

type Props = Partial<{
  title: string
  name: string
  description: string
  pageImage: string
}>

const PageMeta: React.FC<Props> = (props) => {
  const { title, name, description, pageImage } = props
  const { siteTitle, siteDescription, siteUrl } = useSiteMetadata()
  const { pathname } = useLocation()

  const meta = {
    title: title || siteTitle,
    name,
    description: description || siteDescription,
    url: `${siteUrl}${pathname}`,
    image: pageImage || `${siteUrl}/img/og-image.png`,
  }

  return (
    <Helmet>
      <html lang="ja" />
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <link rel="apple-touch-icon" sizes="180x180" href={`${withPrefix('/')}img/apple-touch-icon.png`} />
      <link rel="icon" type="image/png" href={`${withPrefix('/')}img/favicon-32x32.png`} sizes="32x32" />
      <link rel="icon" type="image/png" href={`${withPrefix('/')}img/favicon-16x16.png`} sizes="16x16" />
      <link rel="mask-icon" href={`${withPrefix('/')}img/safari-pinned-tab.svg`} color="#ff4400" />
      <meta name="theme-color" content="#fff" />
      {/* OGP */}
      <meta property="og:type" content="business.business" />
      <meta property="og:title" content={meta.title} />
      <meta property="og:site_name" content={meta.name} />
      <meta property="og:url" content={meta.url} />
      <meta property="og:image" content={meta.image} />
      {/* <meta property="fb:app_id" content="" /> */}
      {/* <meta property="article:published_time" content="" /> */}
      {/* <meta property="article:modified_time" content="" /> */}
      <meta name="twitter:card" content="summary_large_image" />
      {/* <meta name="twitter:site" content="" /> */}
      <meta name="twitter:domain" content="creaith.jp" />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image" content={meta.image} />
    </Helmet>
  )
}

export default PageMeta
