import React from 'react'
import styled from 'src/styles'

type Props = {
  handleEvent: () => void
}

export const Overlay: React.FC<Props> = (props) => {
  const { handleEvent } = props
  return <ScOverlay onClick={() => handleEvent()} />
}

const ScOverlay = styled.div`
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
`
