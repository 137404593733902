import React from 'react'
import styled, { mediaQuery } from 'src/styles'

type Props = {
  hasFeaturedImage: boolean
}

const SiteWrapper: React.FC<Props> = (props) => {
  const { hasFeaturedImage, children } = props

  return <ScWrapper hasFeaturedImage={hasFeaturedImage}>{children}</ScWrapper>
}

export default SiteWrapper

const ScWrapper = styled.div<{ hasFeaturedImage: boolean }>`
  padding-top: ${({ hasFeaturedImage }) => (hasFeaturedImage ? '65px' : 'calc(65px + 24px)')};
  padding-right: 1rem;
  padding-left: 1rem;
  position: relative;
  ${mediaQuery('sm')} {
    padding-top: ${({ hasFeaturedImage }) => (hasFeaturedImage ? '86px' : 'calc(86px + 80px)')};
    padding-right: 2rem;
    padding-left: 2rem;
  }
`
