import React from 'react'
import { Link } from 'gatsby'
import styled from 'src/styles'
import { Inner } from 'src/components/layouts/Inner'

const Footer: React.FC = () => {
  return (
    <footer>
      <ScInner>
        <ScNavi>
          <ScNaviItem>
            <Link to="/privacy">プライバシーポリシー</Link>
          </ScNaviItem>
          <ScNaviItem>
            <Link to="/contact">お問い合わせ</Link>
          </ScNaviItem>
        </ScNavi>
        <ScCopyright>2020 © Creaith, Inc. All Rights Reserved.</ScCopyright>
      </ScInner>
    </footer>
  )
}

export default Footer

const ScInner = styled(Inner)`
  border-top: 1px solid ${({ theme }) => theme.color.text.main};
  padding-top: 40px;
  padding-bottom: 40px;
`

const ScNavi = styled.ul`
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`

const ScNaviItem = styled.li`
  display: flex;
  align-items: center;
  &:not(:last-of-type) {
    &::after {
      content: '｜';
      display: block;
      margin: 0 4px;
    }
  }
  a {
    color: ${({ theme }) => theme.color.text.main};
  }
`

const ScCopyright = styled.small`
  display: block;
  font-size: 0.75rem;
  text-align: center;
`
