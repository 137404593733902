import React from 'react'
import styled, { theme as themeStyle, mediaQuery } from 'src/styles'
import { Link } from 'gatsby'
import useScrollPosition from '@react-hook/window-scroll'
import { Inner } from 'src/components/layouts/Inner'
import { ComponentStyleProps } from 'src/types'
import { AppLogo } from 'src/components/atoms/AppLogo'
import { GlobalMenu } from 'src/components/domains/app/GlobalMenu'
import { useMediaQuery } from 'src/hooks'
import { MenuButton } from 'src/components/domains/app/MenuButton'
import { Overlay } from 'src/components/atoms/Overlay'
import MobileMenu from 'src/components/domains/app/MobileMenu'
import { CreaithBorder } from 'src/components/domains/app/CreaithBorder'

type Props = ComponentStyleProps

const Header: React.FC<Props> = (props) => {
  const scrollY = useScrollPosition(60)
  const [isOpen, setIsOpen] = React.useState(false)
  const isMobile = !useMediaQuery(`(min-width: ${themeStyle.base.viewport.sm}px)`)

  React.useEffect(() => {
    if (!isMobile) {
      setIsOpen(false)
    }
  }, [isMobile])

  return (
    <>
      <ScHeader {...props} scrollY={scrollY}>
        <ScCreaithBorder />
        <ScInner>
          <Link to="/">
            <ScAppLogo display="block" />
          </Link>
          <GlobalMenu />
        </ScInner>
      </ScHeader>
      {/* Mobile menu */}
      <ScMenuButton isOpen={isOpen} handleEvent={() => setIsOpen(!isOpen)} className="u-dn-lg u-ml-16" />
      {isOpen && <Overlay handleEvent={() => setIsOpen(!isOpen)} />}
      <MobileMenu isOpen={isOpen} />
    </>
  )
}

export default Header

const ScHeader = styled.header<{ scrollY: number }>`
  background-color: #fff;
  padding-right: 1rem;
  padding-left: 1rem;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  transition: 0.2s;
  ${({ scrollY }) =>
    scrollY > 80
      ? `
      box-shadow: 0 12px 24px -8px rgba(0, 0, 0, 0.1);
  `
      : `
      box-shadow: none;
  `}
`

const ScInner = styled(Inner)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0 calc(1rem - 4px);
  ${mediaQuery('sm')} {
    padding: 1.5rem 0 calc(1.5rem - 4px);
  }
`

const ScAppLogo = styled(AppLogo)`
  width: 140px;
  ${mediaQuery('sm')} {
    width: 160px;
  }
`

const ScMenuButton = styled(MenuButton)`
  position: fixed;
  top: 15px;
  right: 16px;
  z-index: 100;
  ${mediaQuery('sm')} {
    display: none;
  }
`

const ScCreaithBorder = styled(CreaithBorder)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
`
