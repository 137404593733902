import React from 'react'
import styled, { mediaQuery } from 'src/styles'
import { Link } from 'gatsby'
import { v4 as uuid } from 'uuid'

export const menuList: { label: string; to: string }[] = [
  {
    label: '会社概要',
    to: '/company',
  },
  {
    label: 'サービス一覧',
    to: '/service',
  },
  {
    label: '採用情報',
    to: '/recruit',
  },
  {
    label: 'お問い合わせ',
    to: '/contact',
  },
]

export const GlobalMenu: React.FC = () => {
  const pathname = typeof window !== 'undefined' ? window.location.pathname : ''
  return (
    <ScNavi>
      {menuList.map((v) => (
        <ScNaviItem key={uuid()}>
          <Link to={v.to}>{v.label}</Link>
          {pathname.includes(v.to) && <ScCurrentBar />}
        </ScNaviItem>
      ))}
    </ScNavi>
  )
}

const ScNavi = styled.nav`
  align-items: center;
  display: none;
  ${mediaQuery('sm')} {
    display: flex;
  }
`

const ScNaviItem = styled.div`
  position: relative;
  &:not(:last-of-type) {
    margin-right: 40px;
  }
  a {
    color: ${({ theme }) => theme.color.text.main};
    font-weight: bold;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
    }
  }
`

const ScCurrentBar = styled.div`
  border-radius: 2px;
  height: 4px;
  position: absolute;
  right: -8px;
  bottom: -8px;
  left: -8px;
  background: linear-gradient(
    90deg,
    rgba(30, 185, 170, 1) 0%,
    rgba(130, 80, 150, 1) 33.333%,
    rgba(220, 25, 100, 1) 66.666%,
    rgba(250, 200, 0, 1) 100%
  );
`
