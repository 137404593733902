import React from 'react'
import styled from 'src/styles/'
import { ComponentStyleProps } from 'src/types'

type Props = ComponentStyleProps

export const CreaithBorder: React.FC<Props> = (props) => {
  return <ScWrapper {...props} />
}

const ScWrapper = styled.div`
  background: linear-gradient(
    90deg,
    rgba(30, 185, 170, 1) 0%,
    rgba(130, 80, 150, 1) 33.333%,
    rgba(220, 25, 100, 1) 66.666%,
    rgba(250, 200, 0, 1) 100%
  );
  height: 0.25rem;
`
