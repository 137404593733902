import React from 'react'
import { ComponentStyleProps } from 'src/types'
import styled from '../../styles'

type Props = {
  maxWidth?: number
} & ComponentStyleProps

export const Inner: React.FC<Props> = (props) => {
  const { children } = props
  return <ScInner {...props}>{children}</ScInner>
}

// maxWidth 1328
const ScInner = styled.div<Props>`
  ${({ maxWidth = 1200 }) => `
    max-width: ${maxWidth}px;
  `}
  margin-right: auto;
  margin-left: auto;
  position: relative;
`
