import React from 'react'
import { Link } from 'gatsby'
import { v4 as uuid } from 'uuid'
import styled from 'src/styles'
import { menuList } from './GlobalMenu'

type Props = {
  isOpen: boolean
}

const MobileMenu: React.FC<Props> = (props) => {
  const { isOpen } = props
  return (
    <ScWrapper isOpen={isOpen}>
      {menuList.map((m) => (
        <MenuItem key={uuid()}>
          <Link to={m.to}>{m.label}</Link>
        </MenuItem>
      ))}
    </ScWrapper>
  )
}

export default MobileMenu

const ScWrapper = styled.aside<{ isOpen: boolean }>`
  background-color: #fff;
  padding: 40px 32px;
  width: 208px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  transition: 0.2s;
  transform: translateX(290px);
  ${({ isOpen }) =>
    isOpen &&
    `
    transform: translateX(0);
  `}
  /* small device */
  @media (min-width: 321px) {
    padding: 88px 32px 40px;
  }
`

const MenuItem = styled.div`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 36px;
  &:last-of-type {
    margin-bottom: 48px;
  }
  ${({ theme }) => `
    a {
      color: ${theme.color.text.main};
    }
  `}
`
